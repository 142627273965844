import { GetSeoSettingsDocument } from '@/generated/graphql';
import { initializeApollo, addApolloState } from '@/lib/apolloClient';
import { GetStaticProps } from 'next';

const FourOhFour = () => {
  return <h1>Error path</h1>;
};

export const getStaticProps: GetStaticProps = async context => {
  const { locale } = context;
  const apolloClient = initializeApollo({ locale });

  await Promise.all([
    apolloClient.query({
      query: GetSeoSettingsDocument,
    }),
  ]);
  return addApolloState(apolloClient, {
    props: {},
    revalidate: 30,
  });
};

export default FourOhFour;
